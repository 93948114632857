import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"
import styled from "styled-components"
import { darkBlue, Titillium } from "../palette"
import Menu from "../../svg/menu.svg"
import Logo from "../logo/Logo"
import MenuOverlay from "./MenuOverlay"

const StyledHeader = styled.header`
  background: ${darkBlue};
`
const Row = styled.div`
  display: flex;
  align-items: center;
`
const MenuWrapper = styled.div`
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  @media (min-width: 1100px) {
    display: none;
  }
`

const Header = ({ siteTitle, selectedPath,
  tableOfContent }) => {
  const [open, setOpen] = useState(false);
  return (
  <StyledHeader>
    <div
      style={{
        margin: `0 auto`,
        padding: `1rem 1rem`,
      }}
      >
      <Row>
        <MenuWrapper>
          <Menu onClick={() => setOpen(!open)}/>
      <MenuOverlay show={open} selectedPath={selectedPath} tableOfContent={tableOfContent}onMenuItemClick={() => setOpen(false)}/>
        </MenuWrapper>
        <Logo>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </Logo>
      </Row>
    </div>
  </StyledHeader>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
