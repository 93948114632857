import React from "react"
import styled from "styled-components"
import "../components/layout.css"

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    
`

const TableOfContent = ({ headings, onClick }) => {
    return (
    <StyledDiv>
      {headings
      .map((h) => {
          return <a style={{
              marginLeft: `${h.depth * 0.5}rem`
          }}href={`#${h.id}`} onClick={onClick}>{`${h.value}`}</a>
      })}

      
    </StyledDiv>
  );
    }

  export default TableOfContent;