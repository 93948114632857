import styled from "styled-components"
import { Titillium } from "../palette"


export const Logo = styled.div`
  font-family: ${Titillium};
  font-weight: bolder;
  font-size: 30px;
  letter-spacing: -1px;
`

export default Logo
