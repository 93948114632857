import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { green, orange, backgroundBlue } from "./palette"

export const PrimaryButton = styled(Link)`
  background: ${green};
  text-decoration: none;
  padding: 0.7rem 1rem;
  display: inline-block;
  font-weight: bolder;
  color: white;
  border-radius: 0.5rem;
  cursor: pointer;
`

export const SecondaryButton = styled.a`
  color: white;
  font-weight: bolder;
  cursor: pointer;
  text-decoration: none;
  border-radius: 30px;
  border: white 1px solid;
  padding: 0.5rem 1rem;
  &:hover {
    text-decoration: none;
  }
`

export const StandardButton = styled.div`
  font-weight: bolder;
  cursor: pointer;
  text-decoration: none;
  border-radius: 30px;
  border: white 1px solid;
  padding: 0.5rem 1rem;
  display:inline-flex;
  margin: 0.5rem;
  &:hover {
    text-decoration: none;
  }
`

export const SmallButton = styled(StandardButton)`
  color: ${orange};
  border: ${orange} 1px solid;
`

export const SelectedButton = styled(SmallButton)`
  color: ${backgroundBlue};
  background: ${orange};
`