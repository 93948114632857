import React from "react";
import styled from "styled-components";
import PageList from "../../templates/PageList";

const MenuContainer = styled.div`
  top: 78px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  z-index: 10;
  position: absolute;
  background: white;
  max-width: 500px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  @media (max-width: 800px) {
    .menuOverlay {
      max-width: 100%;
    }
  }
`

interface MenuOverlayProps {
  show: boolean;
  selectedPath: string;
  tableOfContent: any;
  onMenuItemClick: () => void;
}

const MenuOverlay = ({ show, selectedPath, tableOfContent, onMenuItemClick }: MenuOverlayProps) => {
  if (show) {
    return (
      <MenuContainer>
        <PageList selectedPath={selectedPath} tableOfContent={tableOfContent} callback={onMenuItemClick}/>
      </MenuContainer>
    )
  } else {
    return <></>
  }
}

export default MenuOverlay;
