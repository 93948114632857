// List of material.io standards for breakpoints
// https://material.io/design/layout/responsive-layout-grid.html#breakpoints
export enum Breakpoint {
  small = "720px",
  medium = "800px",
}
export enum BreakpointNumber {
  small = 720,
  medium = 800,
}
