import React from "react"
import Header from "../components/documentation/Header"
import Footer from "../components/Footer"
import SEO from "../components/seo"
import { PrimaryButton } from "../components/Buttons"
import { primaryBlue } from "../components/palette"
import styled from "styled-components"
import { Breakpoint } from "../components/breakpoints"
import { graphql, Link, useStaticQuery } from "gatsby"
import "../components/layout.css"
import TableOfContent from "./TableOfContent"

const FixedNav = styled.nav`
  position: fixed;
 
  overflow-y: scroll;
  max-height: calc(100vh - 80px);
  @media (max-width: 1100px) {
    display: none;
  }
`

const Page = styled.li`
  list-style: none;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`

const NotSelectedLink = styled(props => <Link {...props} />)``

const SelectedLink = styled(props => <Link {...props} />)`
  color: #203864 !important;
  font-weight: bold !important;
  font-size: bold !important;
`

const PaddedDiv = styled.div`
  margin-left: 2rem;
`

const ContentList = styled.ul`
 a {
    text-decoration: none;
    font-weight: bold;
    font-size: 0.9rem;
    color: #635e69;
    margin-top: 0.2rem;
    margin-bottom: 0.25rem;
    letter-spacing: 0.025rem;
    font-weight: normal;
  }
`

const PageList = ({
  selectedPath,
  tableOfContent,
  callback,
}: {
  selectedPath: string
  tableOfContent: any
  callback?: ()=> void
}) => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark {
          edges {
            node {
              frontmatter {
                path
                title
                index
              }
            }
          }
        }
      }
    `
  )

  const pages = [...allMarkdownRemark.edges]
  
  const filteredPages = selectedPath.indexOf("docs/no") > -1 ? pages.filter((p) => p.node.frontmatter.path.indexOf("docs/no") > -1) : pages.filter((p) => p.node.frontmatter.path.indexOf("docs/no") === -1)

  filteredPages.sort((a, b) => a.node.frontmatter.index - b.node.frontmatter.index)
  return (

    <ContentList>
      {filteredPages.map((item, i) => {
        const page = item.node.frontmatter
        return (
          <Page>
            {selectedPath === page.path ? (
              <>
                <SelectedLink to={page.path}>{page.title}</SelectedLink>
                <PaddedDiv>
                  <TableOfContent headings={tableOfContent} onClick={callback ? callback : () => {}}/>
                </PaddedDiv>
              </>
            ) : (
              <NotSelectedLink to={page.path}>{page.title}</NotSelectedLink>
            )}
          </Page>
        )
      })}
    </ContentList>
  )
}

export const FixedPageList = ({
  selectedPath,
  tableOfContent,
}: {
  selectedPath: string
  tableOfContent: any
}) => {
  return (
    <FixedNav>
      <PageList selectedPath={selectedPath} tableOfContent={tableOfContent} />
    </FixedNav>
  )
}

export default PageList
