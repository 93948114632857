export const primaryBlue = "#203864"
export const darkBlue = "#0B1525"
export const orange = "#cb7428"
export const brightOrange = "#f59e0b"
export const green = "#53C68C"
export const grey30 = "#F3F5F9"
export const darkGreen = "#243539"

export const backgroundBlue = "#111E36"

export const Titillium = "Titillium Web"
