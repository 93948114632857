import React from "react"
import styled from "styled-components"

const StyledSection = styled.footer`
  padding: 3em 0;
  
`

const Row = styled.div`
  display: flex;
  justify-content: space-around;
`

const CenterVerticalRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const Column = styled.div`
  padding: 0 1rem;
`

const Ul = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 0;
`

const Li = styled.li`
  font-weight: normal;
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
`

const H3 = styled.h3`
  margin-bottom: 0.4rem;
  font-size: 1rem;
`

const Navigation = () => (
  <Row>
    <Column>
      <H3>Company</H3>
      <Ul>
        <Li>Our vision</Li>
        <Li>Contact</Li>
        <Li>News</Li>
      </Ul>
    </Column>
    <Column>
      <H3>Products</H3>
      <Ul>
        <Li>Commander</Li>
        <Li>Scout</Li>
        <Li>Detection</Li>
        <Li>API</Li>
      </Ul>
    </Column>
  </Row>
)

const GreyH4 = styled.h4`
  color: #36313d
`

const Footer = () => (
  <StyledSection>
    <CenterVerticalRow>
      <GreyH4>&copy; 2021 Triangula</GreyH4>
      <Navigation />
    </CenterVerticalRow>
  </StyledSection>
)

export default Footer
